
import Vue from "vue";

export default Vue.extend({
  name: "OnlinePayments",

  data: () => ({
    model: {} as any
  }),

  async mounted() {
    await this.loadData();
  },

  methods: {
    async loadData() {
      try {
        const params = {
          CustomerID: "0000000000000",
          CustomerName: "Test",
          Validate: ""
        };

        this.model = await this.$API.orders().getPaymentData(params);
      } catch (e) {
        await this.$store.dispatch("alert/showError", e.message || e);
      }
    }
  }
});
